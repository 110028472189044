<template>
    <div class="informationen">
        <h1 class="text-center">Standorte</h1>
        <p class="lead">Die Spielgruppe Hochdorf hat zwei Räume im Pfarreizentrum St. Martin. Die Kinder werden nach Quartier eingeteilt.</p>

        <div class="container-fluid margin-top-50 margin-bottom-75">
            <div class="row">
                <div class="col-xs-12 col-sm-6 entry">
                    <h4> St. Martin A, UG, Zimmer 5</h4>
                    <img src="../assets/img/standort_pfarrei-st-martin_0.png" class="entry-image">
                </div>
                <div class="col-xs-12 col-sm-6 entry">
                    <h4> St. Martin A, 2. OG, Zimmer 16</h4>
                    <img src="../assets/img/standort_pfarrei-st-martin.png" class="entry-image">
                </div>
                
            </div>
            
            <div class="row">
                    <div class="col-xs-12 col-sm-3 entry">
                        <img src="../assets/img/standort_pfarrei-st-martin_1.png" class="entry-image">
                    </div>
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_2.png" class="entry-image">
                </div>
                
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_5.jpeg" class="entry-image">
                </div>
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_6.jpeg" class="entry-image">
                </div>
                
            </div>
        
            
            <div class="row">
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_3.png" class="entry-image">
                </div>
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_4.png" class="entry-image">
                </div>
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_7.jpeg" class="entry-image">
                </div>
                <div class="col-xs-12 col-sm-3 entry">
                    <img src="../assets/img/standort_pfarrei-st-martin_8.jpeg" class="entry-image">
                </div>
                
            </div>        
        </div>
        
    
        
        
    </div>

</template>

<script>
export default {
    name: 'Informationen'
}
</script>
